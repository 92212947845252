const baseUrl = "https://www.api.logimail.site/";

//const baseUrl = "https://localhost:7145/";

export async function getAllGroups(fromEmail) {
  try {
    const response = await fetch(baseUrl + "api/User/UserGroups?email=" + window.localStorage.getItem("fromEmail"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch user groups");
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching user groups:", error);
    throw error;
  }
}
export async function createGroup(group) {
  return new Promise(function (resolve, reject) {
    const data = {
      addinUser: {
        userEmail: window.localStorage.getItem("fromEmail"),
      },
      mailGroup: {
        id: group.id,
        groupName: group.groupName,
        userEmails: group.emails,
        mailsCount: group.mailsCount.toString(),
      },
    };

    fetch(baseUrl + "api/User/UserGroups", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update database");
        }
        return response;
      })
      .then((response) => {
        console.log(response);
        resolve();
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject(error);
      });
  });
}
export async function updateGroup(group) {
  return new Promise(function (resolve, reject) {
    const data = {
      addinUser: {
        userEmail: window.localStorage.getItem("fromEmail"),
      },
      mailGroup: {
        id: group.id,
        groupName: group.groupName,
        userEmails: group.emails,
        mailsCount: group.mailsCount.toString(),
      },
    };

    fetch(baseUrl + "api/User/UserGroups", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update database");
        }
        return response;
      })
      .then((response) => {
        console.log(response);
        resolve();
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject(error);
      });
  });
}

export async function favoriteGroup(group) {
  return new Promise(function (resolve, reject) {
    fetch(baseUrl + "api/User/FavoriteGroup", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(group),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update database");
        }
        return response;
      })
      .then((response) => {
        console.log(response);
        resolve();
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject(error);
      });
  });
}

export async function deleteGroup(id) {
  return new Promise(function (resolve, reject) {
    fetch(baseUrl + "api/User/UserGroups?id=" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update database");
        }
        return response;
      })
      .then((response) => {
        console.log(response);
        resolve();
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject(error);
      });
  });
}

export async function getSubscriptionDetail() {
  try {
    const response = await fetch(
      baseUrl + "api/User/UserSubscription/?email=" + window.localStorage.getItem("fromEmail"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 204) {
      return null; // or return {} if you prefer returning an empty object
    }

    if (response.status === 200) {
      // OK, parse and return the JSON data
      const data = await response.json();
      return data;
    }

    // For other status codes, throw an error with a descriptive message
    throw new Error(`Unexpected status code: ${response.status}`);
  } catch (error) {
    console.error("Error fetching user subscription details:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
}
